/**
 * An uploader with multipart, resumption support.
 */

import { RpxResponse, rpx } from './rpx';
import { stripSVG } from 'shared/media';

export type UploadState = {
  status: 'init' | 'uploading' | 'finishing' | 'done' | 'failed';
  progress: number;
};

type UploadOpts = {
  file: Blob;
  isPublic?: boolean;
  onProgress(state: UploadState): void;
};

type UploadContext = RpxResponse<typeof rpx.files.beginUpload> & {
  /**
   * A map of upload part index to etag.
   */
  etags: Map<number, string>;
  file: Blob;
  parts: Array<{ partNumber: number; url: string }>;
  terminated: boolean;
};

/**
 * In a multipart upload, this uploads the specified part.
 */
async function uploadPart(opts: { file: Blob; partSize: number; partNumber: number; url: string }) {
  const start = opts.partNumber * opts.partSize;
  const end = Math.min(start + opts.partSize, opts.file.size);
  const body = opts.file.slice(start, end);
  const numRetries = 3;
  let error: any;
  for (let i = 0; i < numRetries; ++i) {
    try {
      const response = await fetch(opts.url, {
        method: 'PUT',
        headers: {
          'Content-Type': opts.file.type,
          'Content-Length': body.size.toString(),
        },
        body,
      });
      // ETag is wrapped in quotes, but the finalization call needs this to be unquoted
      // so, slice(1, -1) removes the quotes.
      const etag = response.headers.get('ETag')?.slice(1, -1) || '';
      const text = await response.text();
      if (response.status >= 400) {
        throw new Error(text);
      }
      return etag;
    } catch (err) {
      error = err;
      console.error(err);
    }
  }
  throw error;
}

async function sanitizeFile(file: Blob) {
  if (!file.type.toLowerCase().includes('svg')) {
    return file;
  }
  const svg = await file.text();
  const stripped = stripSVG(svg);
  return new File([stripped], file.name, { type: file.type });
}

function getProgress(ctx: UploadContext) {
  return ctx.presignedUrls.length ? (ctx.etags.size / ctx.presignedUrls.length) * 100 : 0;
}

async function makeUploadContext(opts: UploadOpts): Promise<UploadContext> {
  const file = await sanitizeFile(opts.file);
  opts.onProgress({ status: 'init', progress: 0 });
  const ctx = await rpx.files.beginUpload({
    size: file.size,
    type: file.type,
    name: file.name,
  });
  return {
    ...ctx,
    terminated: false,
    file,
    parts: ctx.presignedUrls.map((url, partNumber) => ({ url, partNumber })),
    etags: new Map(),
  };
}

async function uploadParts(opts: UploadOpts, ctx: UploadContext) {
  while (ctx.parts.length && !ctx.terminated) {
    const part = ctx.parts.shift();
    if (part == null) {
      break;
    }
    try {
      const etag = await uploadPart({
        ...part,
        file: ctx.file,
        partSize: ctx.partSize,
      });
      ctx.etags.set(part.partNumber + 1, etag);
      opts.onProgress({
        status: ctx.terminated ? 'failed' : 'uploading',
        progress: getProgress(ctx),
      });
    } catch (err) {
      ctx.parts.push(part);
      if (!ctx.terminated) {
        ctx.terminated = true;
        throw err;
      }
    }
  }
}

/**
 * Create an uploader object which handles progress, resumption, and can abort a multipart
 * upload prior to completion.
 */
export function makeUploader(opts: UploadOpts) {
  const parallelism = 4;
  let uploadContext: UploadContext | undefined;

  return {
    async upload() {
      uploadContext ||= await makeUploadContext(opts);
      const ctx = uploadContext;
      ctx.terminated = false;
      opts.onProgress({ status: 'uploading', progress: getProgress(ctx) });
      await Promise.all(new Array(parallelism).fill(0).map(() => uploadParts(opts, ctx)));
      if (ctx.terminated) {
        return;
      }
      opts.onProgress({ status: 'finishing', progress: 100 });
      const result = await rpx.files.finishUpload({
        fileId: ctx.fileId,
        type: ctx.file.type,
        name: ctx.file.name,
        isPublic: opts.isPublic,
        uploadId: ctx.uploadId,
        filePath: ctx.filePath,
        etags: Array.from(ctx.etags.entries())
          .map(([k, v]) => ({ part: k, etag: v }))
          .sort((a, b) => a.part - b.part),
      });
      // Clear upload info before we notify of completion. This way, abort is a noop
      ctx.terminated = true;
      opts.onProgress({ status: 'done', progress: 100 });
      return { name: ctx.file.name, type: ctx.file.type, size: ctx.file.size, ...result };
    },

    async abort() {
      if (!uploadContext || uploadContext.terminated) {
        return;
      }
      uploadContext.terminated = true;
    },
  };
}
